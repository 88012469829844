import { Component } from 'react';
import queryString from 'query-string';
import { RouteComponentProps } from 'react-router-dom';
import LoadingGif from 'survey/assets/images/loading-grey.gif';
import * as Sentry from '@sentry/browser';
import axios from 'axios';

import { Header, Footer, Page, ThankYouPage, Theme } from 'survey/components';
import { getAnswersForSubmit } from 'survey/selectors';

import {
  AppState,
  PageAPI,
  PageSchema,
  UpdateAnswerFuncValue,
  SurveyParams,
  SocialParams
} from 'survey/types';

import AppContext, { initialState } from 'survey/contexts/AppContext';
import { normalize, schema } from 'normalizr';
import InitializationContext from 'survey/contexts/InitializationContext';
import FinishedPage from 'survey/components/FinishedPage';
import ExpiredPage from 'survey/components/ExpiredPage';

interface AppRouteParams {
  uuid: string;
}
class App extends Component<RouteComponentProps<AppRouteParams>, AppState> {
  static contextType = InitializationContext;

  constructor(props: RouteComponentProps<AppRouteParams>) {
    super(props);
    this.state = initialState;
  }

  componentDidMount(): void {
    /* eslint-disable @typescript-eslint/naming-convention */
    const {
      REACT_APP_API_BASE_URL: api_base_url,
      REACT_APP_RESPONSE_API_BASE_URL: response_api_base_url,
      REACT_APP_FACEBOOK_APP_ID: facebook_app_id,
      REACT_APP_ENV: app_env,
    } = this.context;
    /* eslint-enable @typescript-eslint/naming-convention */

    this.setState(
      {
        isLoading: true,
        saveAnswers: this.saveAnswers,
        api_base_url,
        response_api_base_url,
        facebook_app_id,
        app_env,
      },
      (): void => {
        this.fetchAppManifest();
      }
    );
  }

  setPageFromUrl = (): void => undefined; // Preview method

  fetchAppManifest = (): void => {
    Sentry.addBreadcrumb({
      category: 'network',
      message: 'Fetching app configuration',
    });
    this.saveAnswerFromUrl();
    this.fetchSurvey();
  };

  saveAnswerFromUrl = (): void => {
    const {
      location: { search },
    } = this.props;
    const { option: optionId, question: questionId } = queryString.parse(
      search,
      { parseNumbers: true }
    );

    if (!optionId || !questionId) return;

    Sentry.addBreadcrumb({
      category: 'user',
      message: `Set answer (from URL) for question ${questionId}`,
    });

    this.saveAnswers({
      answer_from_email: {
        question_id: questionId as number,
        option_ids: [optionId as number],
      },
    });
  };

  setAnswerFromUrl = (): void => {
    const {
      location: { search },
    } = this.props;
    const { finished, expired, entities } = this.state;
    const { option: optionId, question: questionId } = queryString.parse(
      search,
      { parseNumbers: true }
    );

    if (!optionId || !questionId) return;
    if (finished || expired || !entities) return;

    const option = entities.options[optionId as number];
    const question = entities.questions[questionId as number];

    if (!option || !question) return;

    this.updateAnswer(questionId as number, {
      option_ids: [option.id],
      value: option.value,
    });
  };

  updateAnswer = (questionId: number, value: UpdateAnswerFuncValue): void => {
    const { answers } = this.state;

    Sentry.addBreadcrumb({
      category: 'user',
      message: `Answered question ${questionId}`,
      data: value,
    });

    this.setState({
      answers: {
        ...answers,
        [questionId]: {
          question_id: questionId,
          ...answers[questionId],
          ...value,
        },
      },
    });
  };

  saveAnswers = (payload: SurveyParams | SocialParams): void => {
    const {
      match: { params },
    } = this.props;
    const { response_api_base_url } = this.state; // eslint-disable-line @typescript-eslint/naming-convention
    const { apm } = this.context;

    Sentry.addBreadcrumb({
      category: 'network',
      message: 'Save answers',
      data: payload,
    });

    axios
      .put(
        `${response_api_base_url}/v2/feedback/responses/${params.uuid}`,
        payload
      )
      .then((response): void => {
        Sentry.addBreadcrumb({
          category: 'network',
          message: `Answers saved [${response.status}]`,
        });
      })
      .catch((err): void => {
        const error = new Error(
          `Failed to save answers, Original Error: ${err.message}`
        );

        Sentry.withScope((scope): void => {
          scope.setExtra('response', err.response);
          scope.setExtra('message', err.message);
          Sentry.captureException(error);
        });
        if (apm) apm.captureError(error);
      });
  };

  submitPage = (): void => {
    const { result, currentPage } = this.state;
    const finished = currentPage + 1 > result.length;
    const payload = {
      answers: getAnswersForSubmit(this.state),
      finished,
    } as SurveyParams;

    Sentry.addBreadcrumb({
      category: 'user',
      message: `Submitted page ${currentPage}`,
    });

    // TODO: make SurveyParamsAnswer and Answer types compatible; remove assertion
    this.saveAnswers(payload);
    this.setState({ currentPage: currentPage + 1 });
  };

  surveyURL = (): string => {
    const {
      match: { params },
    } = this.props;
    const { response_api_base_url } = this.state; // eslint-disable-line @typescript-eslint/naming-convention

    return `${response_api_base_url}/v1/survey_configurations/${params.uuid}`;
  };

  fetchSurvey(): void {
    const { apm } = this.context;

    Sentry.addBreadcrumb({
      category: 'network',
      message: 'Fetch survey configuration from Connect',
    });

    axios
      .get(this.surveyURL())
      .then((response): void => {
        Sentry.addBreadcrumb({
          category: 'network',
          message: `Configuration fetched [${response.status}]`,
        });

        /* eslint-disable @typescript-eslint/naming-convention */
        const {
          data: {
            pages,
            context,
            thank_you,
            submit,
            texts,
            links,
            global_texts,
          },
        } = response;
        /* eslint-enable @typescript-eslint/naming-convention */

        Sentry.addBreadcrumb({
          category: 'network',
          message: 'Configuration response',
          data: { context, pages },
        });

        const pagesWithId = (pages as []).map(
          (page: PageAPI, index: number): PageSchema => ({
            id: index + 1,
            ...page,
          })
        );

        const optionsSchema = new schema.Entity('options', {});
        const questionsSchema = new schema.Entity('questions', {
          options: [optionsSchema],
        });
        const pagesSchema = new schema.Entity('pages', {
          questions: [questionsSchema],
        });

        const normalizedPages = normalize(pagesWithId, [pagesSchema]);

        this.setState({
          ...normalizedPages,
          ...context,
          submit,
          thank_you,
          links,
          texts,
          global_texts,
        }, () => {
          this.setPageFromUrl();
          this.setAnswerFromUrl();
          this.setState({ isLoading: false });
        });
      })
      .catch((err): void => {
        const error = new Error(
          `Failed to load survey, Original Error: ${err.message}`
        );

        Sentry.configureScope((scope): void => {
          scope.setExtra('response', err.response);
          scope.setExtra('message', err.message);
          Sentry.captureException(error);
        });

        if (apm) apm.captureError(error);

        this.setState({ isLoading: false });
      });
  }

  renderCurrentPage(): JSX.Element | null {
    const {
      brand,
      finished,
      expired,
      result,
      currentPage,
      feature_flags: { lock_finished_survey_answers }, // eslint-disable-line @typescript-eslint/naming-convention
    } = this.state;

    if (lock_finished_survey_answers) {
      if (finished) return <FinishedPage />;

      if (expired) return <ExpiredPage />;
    }

    if (expired || !result.length || currentPage > result.length)
      return <ThankYouPage />;

    return (
      <>
        {brand && <Header />}
        <Page
          currentPage={currentPage}
          updateAnswer={this.updateAnswer}
          submitPage={this.submitPage}
        />
      </>
    );
  }

  renderLoading = (): JSX.Element => (
    <div className="survey-loading">
      <img src={LoadingGif} alt="Loading..." />
    </div>
  );

  render(): JSX.Element {
    const {
      isLoading,
      finished,
      expired,
      feature_flags: { lock_finished_survey_answers }, // eslint-disable-line @typescript-eslint/naming-convention
    } = this.state;

    if (isLoading) return this.renderLoading();

    return (
      <AppContext.Provider value={this.state}>
        <Theme />

        <div>
          <div className="row justify-content-center">
            <div className="wrapper col-xs-12 col-sm-8 col-md-6">
              {this.renderCurrentPage()}
            </div>
          </div>

          {(lock_finished_survey_answers ? !expired && !finished : true) && (
            <Footer />
          )}
        </div>
      </AppContext.Provider>
    );
  }
}

export default App;
